@import "../base";

/* @define Dropdown */
.Dropdown {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.Dropdown-heading {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  font-weight: bold;
  user-select: none;
  padding-right: 1rem;
  cursor: pointer;
  transition: color 125ms var(--ease);
}

.Dropdown-heading:hover {
  color: #23a098;
  cursor: pointer;
  transition: color 125ms var(--ease-out);
}

.Dropdown-heading::after {
  content: "⌝";
  font-size: 1.2em;
  transform: rotate(135deg);
  transform-origin: center;
  margin-left: 0.3rem;
  position: absolute;
  top: -2px;
  right: 0;
}

.Dropdown[open] .Dropdown-heading::after,
.Dropdown.is-open .Dropdown-heading::after {
  transform: rotate(-45deg);
  top: 0;
}

.Dropdown-heading::-webkit-details-marker {
  display: none;
}

.Dropdown-content {
  display: none;
  min-width: calc(100% + 1.9rem);
  padding: 2.7rem 1rem 0.8rem;
  position: absolute;
  left: -1rem;
  top: -1rem;
  background: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 2px 44px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(2.5rem);
  animation: Dropdown-content--appearing 400ms forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.Dropdown-content button {
  width: 100%;
  display: block;
  transition: color 125ms var(--ease);
  text-align: left;
}

.Dropdown-content button:hover {
  color: #23a098;
  cursor: pointer;
  transition: color 125ms var(--ease-out);
}

@keyframes Dropdown-content--appearing {
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

.Dropdown[open] .Dropdown-content,
.Dropdown.is-open .Dropdown-content {
  display: block;
}
