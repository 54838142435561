@import "../base";

/* @define Featured */
.Featured {
  display: flex;
  margin-top: 1.5rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.625rem;
  user-select: none;
}

.Featured.is-expanded {
  box-shadow: 0 2px 44px rgba(0, 0, 0, 0.1);
  transition: box-shadow 150ms var(--ease-out);
}

.Featured.is-expanded:hover {
  transition: box-shadow 150ms var(--ease);
  box-shadow: 0 12px 44px rgba(0, 0, 0, 0.2);
}

.Featured-title {
  display: inline-block;
  border-bottom: 2px solid transparent;
}

.Featured:hover .Featured-title {
  border-bottom: 2px solid currentColor;
}

.Featured.is-expanded {
  margin-top: 3.5rem;
}

.Featured.is-expanded::before {
  content: "";
  display: block;
  padding-top: 33.333%;
}

.Featured-background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: #000;
}

@media (max-width: 899px) {
  .Featured-background::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.Featured-image {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
  z-index: -2;
}

@supports (object-fit: cover) {
  .Featured-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
  }
}

.Featured-body {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  padding: 1.25rem;
  color: #fff;
  width: 100%;
}

@media (min-width: 600px) {
  .Featured-body {
    padding: 3rem;
  }
}

.Featured-header {
  margin-bottom: auto;
}

.Featured-duration {
  display: flex;
  margin-top: 2rem;
  font-weight: bold;
}

.Featured-symbol {
  display: inline-block;
  margin-right: 0.5rem;
  line-height: 1.5;
}

.Featured-link {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  text-indent: -9999rem;
}

.Featured-link:focus-visible {
  border: var(--focus-ring-width) solid rgb(var(--focus-ring-color));
}

.Featured-close {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
  z-index: 1;
  font-weight: bold;
  border-radius: 2rem;
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color 125ms var(--ease);
}

@media (min-width: 600px) {
  .Featured-close {
    bottom: auto;
    top: 1.5rem;
    right: 1.5rem;
  }
}

.Featured-close:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 125ms var(--ease-out);
}

@media (max-width: 899px) {
  .Featured-close {
    background-color: rgba(255, 255, 255, 0.8)
  }

  .Featured-close:hover {
    background-color: rgba(255, 255, 255, 1)
  }
}

.Featured-close::before {
  content: "✕";
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 0.8em;
  font-weight: bold;
}

/**
 * Collapsed state
 */

.Featured-button {
  background: #fff;
  border-radius: 2rem;
  border: var(--focus-ring-width) solid transparent;
  text-align: center;
  padding: calc(0.8rem - var(--focus-ring-width)) calc(2rem - var(--focus-ring-width));
  font-weight: 600;
  font-size: 1.125rem;
  min-width: 9.5rem;
  margin-top: 1.5rem;
  display: inline-block;
  transition: background 125ms var(--ease-out), color 125ms var(--ease-out);
}

.Featured-button:hover {
  color: #fff;
  background: #23a098;
}

.Featured-button:active {
  background: #1a7973;
  transition: none;
}

.Featured-button:focus-visible {
  outline: 0 !important;
  border-color: rgb(var(--focus-ring-color));
}

.Featured-button svg {
  position: relative;
  top: -0.1em;
  margin-right: 0.5rem;
  vertical-align: middle;
}
