@import "../base";

/* @define Lesson */

.Lesson {
  margin-top: 2rem;
}

.Lesson-wrap {
  background: #fff;
  padding: 1.5rem 2rem;
  border-radius: 0.375rem;
  position: relative;
  transition: box-shadow 150ms var(--ease);
}

.Lesson-summary {
  position: relative;
  display: block;
  margin: -1.5rem -2rem;
  padding: 1.5rem 4.5rem 1.5rem 2rem;
  list-style-image: none;
}

.Lesson-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Lesson:first-of-type {
  margin-top: 2rem;
}

.Lesson-checkbox:checked ~ .Lesson-wrap {
  box-shadow: 0 2px 44px rgba(0, 0, 0, 0.1);
}

.Lesson + .Lesson {
  margin-top: 0.75rem;
}

.Lesson-subtitle {
  margin-top: 0.3rem;
  display: block;
  font-weight: 600;
}

.Lesson-subtitle svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -0.15em;
  margin: 0 0.1em 0 0.5em;
}

.Lesson-summary:hover h2 {
  transition: color 125ms var(--ease);
}

.Lesson-summary:hover h2 {
  color: #23a098;
  transition: color 125ms var(--ease-out);
}

.Lesson-summary:active h2 {
  opacity: 0.6;
  transition: none;
}

.Lesson-checkbox:checked ~ .Lesson-wrap .Lesson-summary {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2.75rem;
}

.Lesson-toggle::after {
  content: "";
  position: absolute;
  top: 2.35rem;
  right: 1.7rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20height%3D%228%22%20viewBox%3D%220%200%2014%208%22%20width%3D%2214%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m1078%20663v-7c0-.552285.44772-1%201-1s1%20.447715%201%201v8c0%20.552285-.44772%201-1%201h-8c-.55228%200-1-.447715-1-1s.44772-1%201-1z%22%20fill%3D%22%23ffffff%22%20transform%3D%22matrix(.70710678%20.70710678%20-.70710678%20.70710678%20-286.449314%20-1225.830265)%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
  background-color: #23A098;
  background-size: 0.7rem;
  flex: 0 0 auto;
  transition: transform 200ms var(--ease);
  transform-origin: center center;
  will-change: transform;
}

.Lesson-checkbox:checked ~ .Lesson-wrap Lesson-toggle::after {
  transform: rotate(180deg);
}

.Lesson-content {
  padding: 0 0 2rem;
  display: none;
}

.Lesson-checkbox:checked ~ .Lesson-wrap .Lesson-content {
  display: flex;
  flex-wrap: wrap-reverse;
}

@media (min-width: 1000px) {
  .Lesson-toggle::before {
    content: "Visa";
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    top: 2.7rem;
    right: 4.4rem;
  }

  .Lesson-checkbox:checked ~ .Lesson-wrap .Lesson-toggle::before {
    content: "Stäng";
  }

  .Lesson-summary {
    padding-right: 10rem;
  }

  .Lesson-content {
    padding: 0 1.25rem 2rem;
  }
}

/**
 * Steps
 */

.Lesson-steps {
  margin-top: 2.5rem;
  counter-reset: steps;
}

.Lesson-step {
  padding-left: 3rem;
  position: relative;
  margin-top: 1.5rem;
  counter-increment: steps;
}

.Lesson-step + .Lesson-step {
  margin-top: 2.5rem;
}

.Lesson-step::before {
  content: counter(steps);
  width: 2rem;
  background: #23A098;
  color: #fff;
  border-radius: 50%;
  display: block;
  text-align: center;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: absolute;
  top: -0.2rem;
  left: 0;
}

.Lesson-note {
  margin: 1rem 0 0;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  padding: 1.05rem 1.25rem;
  border-radius: 0.3rem;
  max-width: 37.4rem;
}

/**
 * Aside
 */

.Lesson-aside {
  margin-bottom: 2.5rem;
}

@media (min-width: 1000px) {

  .Lesson-checkbox:checked ~ .Lesson-wrap .Lesson-content {
    flex-wrap: nowrap;
    display: flex;
  }

  .Lesson-aside {
    margin-left: 5rem;
    flex: 2 0 25%;
    margin-top: 0;
    margin-bottom: 0;
  }
}
