@import "../global";

/* @define utilities */
.u-light {
  background: #fff;
}

.u-hidden {
  display: none !important;
}

.u-hiddenVisually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.u-nbfc {
  overflow: hidden;
}

@media print {
  .u-printHidden {
    display: none !important;
  }
}

/**
 * Display
 */

.u-block { display: block !important; }
.u-inlineBlock { display: inline-block !important; }
.u-inline { display: inline !important; }
.u-flex { display: flex !important; }

/**
 * Position
 */

.u-posStatic { position: static !important; }
.u-posRelative { position: relative !important; }
.u-posAbsolute { position: absolute !important; }
.u-posFixed { position: fixed !important; }

/**
 * Aspect ratio
 */

.u-aspect1-1,
.u-aspect4-3,
.u-aspect16-9,
.u-aspectPoster {
  position: relative !important;
}

.u-aspect1-1::before,
.u-aspect4-3::before,
.u-aspect16-9::before,
.u-aspectPoster::before {
  content: "" !important;
  display: block !important;
  height: 0 !important;
}

.u-aspect1-1::before { padding-top: 100% !important; }
.u-aspect4-3::before { padding-top: calc(100% * 3 / 4) !important; }
.u-aspect16-9::before { padding-top: calc(100% * 9 / 16) !important; }
.u-aspectPoster::before { padding-top: calc(100% * 278/195) !important; }

/**
 * Centered page container
 */

.u-container {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: var(--document-margin) !important;
  padding-right: var(--document-margin) !important;
  max-width: calc(var(--container-large-width) + (var(--document-margin) * 2)) !important
}

.u-tight {
  padding-left: calc(var(--document-margin) / 2) !important;
  padding-right: calc(var(--document-margin) / 2) !important;
}

 @supports (padding-left: constant(safe-area-inset-left)) {
  .u-container {
    padding-left: calc(var(--document-margin) + constant(safe-area-inset-left)) !important;
    padding-right: calc(var(--document-margin) + constant(safe-area-inset-right)) !important;
  }

  .u-tight {
    padding-left: calc((var(--document-margin) / 2) + constant(safe-area-inset-left)) !important;
    padding-right: calc((var(--document-margin) / 2) + constant(safe-area-inset-left)) !important;
  }
 }

@supports (padding-left: env(safe-area-inset-left)) {
  .u-container {
    padding-left: calc(var(--document-margin) + env(safe-area-inset-left)) !important;
    padding-right: calc(var(--document-margin) + env(safe-area-inset-right)) !important;
  }

  .u-tight {
    padding-left: calc((var(--document-margin) / 2) + env(safe-area-inset-left)) !important;
    padding-right: calc((var(--document-margin) / 2) + env(safe-area-inset-left)) !important;
  }
}

@media (min-width: 1000px) {
  .u-tightLate {
    padding-left: calc(var(--document-margin) / 2) !important;
    padding-right: calc(var(--document-margin) / 2) !important;
  }

  @supports (padding-left: constant(safe-area-inset-left)) {
    .u-tightLate {
      padding-left: calc((var(--document-margin) / 2) + constant(safe-area-inset-left)) !important;
      padding-right: calc((var(--document-margin) / 2) + constant(safe-area-inset-left)) !important;
    }
   }
  
  @supports (padding-left: env(safe-area-inset-left)) {
    .u-tightLate {
      padding-left: calc((var(--document-margin) / 2) + env(safe-area-inset-left)) !important;
      padding-right: calc((var(--document-margin) / 2) + env(safe-area-inset-left)) !important;
    }
  }
}