@import "../base";

/* @define Accordion */

.Accordion {
  background: #fff;
}

.Accordion-items {
  margin-top: 1.5rem;
}

.Accordion-details {
  display: block;
  padding-right: 2.7rem;
  outline: 0;
  position: relative;
}

.Accordion-items > div {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-width: 0 0 1px 0;
}

.Accordion-items > div:first-of-type {
  border-top-width: 1px;
}

.Accordion-summary {
  position: relative;
  text-align: left;
  list-style: none;
  user-select: none;
  padding: 1.4rem 0 1.4rem 0;
  outline: 0;
  font-size: 1.25rem;
  font-weight: 600;
  transition: color 125ms var(--ease);
  cursor: default;
}

.Accordion-summary:hover {
  color: #23a098;
  cursor: pointer;
  transition: color 125ms var(--ease-out);
}

.Accordion-summary:active {
  opacity: 0.6;
}

.Accordion-body {
  display: none;
}

.Accordion-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Accordion-toggle::after {
  content: "";
  position: absolute;
  top: 1.9rem;
  right: -2.5rem;
  width: 1rem;
  height: 1rem;
  padding: 1rem;
  margin: -1rem;
  background: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20height%3D%228%22%20viewBox%3D%220%200%2014%208%22%20width%3D%2214%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m1078%20663v-7c0-.552285.44772-1%201-1s1%20.447715%201%201v8c0%20.552285-.44772%201-1%201h-8c-.55228%200-1-.447715-1-1s.44772-1%201-1z%22%20fill%3D%22%230f1a26%22%20transform%3D%22matrix(.70710678%20.70710678%20-.70710678%20.70710678%20-286.449314%20-1225.830265)%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 auto;
  transition: transform 200ms var(--ease);
  transform-origin: center center;
  will-change: transform;
  box-sizing: content-box;
  cursor: default;
}

.Accordion-checkbox:checked ~ .Accordion-details .Accordion-body {
  display: block;
}

.Accordion-checkbox:checked ~ .Accordion-details .Accordion-toggle::after {
  transform: rotate(180deg);
}

.Accordion-checkbox:checked ~ .Accordion-details {
  padding-bottom: 1.4rem;
}

.Accordion--inline {
  background: transparent
}
