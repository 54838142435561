@import "../base";

/* @define Header */
:root {
  --Header-height: 4.2rem;
  --Header-margin: 1.5rem;
}

@media (min-width: 350px) {
  :root {
    --Header-margin: 1.5rem;
  }
}

@media (min-width: 400px) {
  :root {
    --Header-margin: 2rem;
  }
}

.Header {
  flex: 0 0 auto;
  height: var(--Header-height);
  position: relative;
  z-index: 100;
  user-select: none;
}

.Header--tabs {
  position: sticky;
  top: 0;
  pointer-events: none;
  display: none;
}

@media (min-width: 1000px) {
  .Header--tabs {
    display: block;
  }
}

.Header-bar {
  display: flex;
  justify-content: space-between;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--Header-height);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}

.Header--tabs .Header-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  position: static;
}

@media (min-width: 1000px) {
  .Header--tabs .Header-bar {
    background-color: transparent;
  }
}

.Header-tab {
  display: flex;
  align-items: center;
  margin: 0 0.75em;
  white-space: nowrap;
  color: rgb(var(--color-muted));
  border-bottom: 4px solid transparent;
  pointer-events: auto;
  transition: color 125ms var(--ease);
}

.Header-tab:hover {
  color: currentColor;
  transition: color 125ms var(--ease-out);
}

.Header-tab:active {
  opacity: 0.6;
}

.Header-tab:hover {
  color: currentColor;
}

.Header-tab.is-selected {
  font-weight: 600;
  color: currentColor;
  border-bottom-color: rgb(var(--color-turquise));
}

.Header-logo {
  display: block;
  width: auto;
  height: 3.2rem;
  margin: 0.5rem calc(var(--Header-margin) - 1rem) -1rem;
  padding: 1rem;
}
.Header-logo svg {
  display: block;
  width: auto;
  height: 20px;
}

.Header-back,
.Header-gg {
  display: inline-flex;
  padding: 0 var(--Header-margin);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
  height: var(--Header-height);
  transition: opacity 150ms var(--ease-out);
}

.Header-back:active,
.Header-gg:active {
  opacity: 0.5;
  transition: none;
}

.Header-back svg {
  width: 0.4375rem;
  height: auto;
  margin-right: 0.5rem;
}

.Header-gg {
  text-transform: none;
  border-right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.Header-gg svg {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin: 0 -0.25rem 0 0.75em;
}

@media (max-width: 500px) {
  .Header-gg {
    font-size: 0;
  }

  .Header-gg svg {
    margin: 0 -0.25rem;
  }
}