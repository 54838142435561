@import "../base";

/* @define Grid */
:root {
  --Grid-gutter: 1.5rem;
  --Grid-gutter-vertical: 1.8rem;
}

@media (min-width: 800px), print {
  :root {
    --Grid-gutter: 2.8rem;
    --Grid-gutter-vertical: 2.5rem;
  }
}

@media (min-width: 1000px), print {
  :root {
    --Grid-gutter: 1.5rem;
    --Grid-gutter-vertical: 3rem;
  }
}

@media (min-width: 1200px), print {
  :root {
    --Grid-gutter: 2.8rem;
  }
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + var(--Grid-gutter));
  margin: 0 calc(var(--Grid-gutter) * -0.5);
  margin-bottom: calc(var(--Grid-gutter-vertical) * -1);
}

/**
 * 1. Add support for truncated text within the grid
 */

.Grid-cell {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  min-width: 0; /* 1 */
  padding: 0 calc(var(--Grid-gutter) * 0.5);
  margin-bottom: var(--Grid-gutter-vertical);
}

.Grid-cell > * {
  flex-basis: 100%;
  min-width: 0; /* 1 */
}

.Grid-cell--appear {
  opacity: 0;
  transform: translateY(100px);
  animation: Grid-cell--appear 600ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@keyframes Grid-cell--appear {
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
