@import "../base";

/* @define Pills */
.Pills {
  display: inline-block;
  list-style: none;
  margin: 0.4rem -0.2rem -0.2rem -0.2rem;
  user-select: none;
}

.Pills-pill {
  display: inline-block;
  margin: 0.2rem;
}

.Pills-item {
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  margin-right: 0.5rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 0;
  overflow: hidden;
  border-radius: 2rem;
  font-weight: bold;
  transform: background-color 200ms ease-out;
  transition: background 125ms var(--ease-out), color 125ms var(--ease-out);
}

.Pills-item.is-selected {
  color: #fff;
  border-color: rgb(var(--color-turquise));
  background: rgb(var(--color-turquise));
  pointer-events: none;
}

.Pills-item:not(.is-selected):hover {
  background: #fff;
}


.Pills--large {
  margin-top: 1rem;
}

.Pills--large .Pills-item {
  transition: background 125ms var(--ease-out), color 125ms var(--ease-out);
}

.Pills--large .Pills-item:not(.is-selected):hover {
  color: #fff;
  background: #23a098;
}

.Pills--large .Pills-item:not(.is-selected):active {
  background: #1a7973;
  transition: none;
}

@media (min-width: 370px) {
  .Pills--large .Pills-item {
    padding: 0.75rem 1.2rem;
  }
}