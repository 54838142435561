:root {
  --document-font-family: futura-pt, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --document-font-size: 1rem;
  --document-line-height: 1.35;

  --document-color: 17, 6, 21;
  --document-background: 255, 229, 242;

  --loading-color: 17, 0, 70, 0.06;
  --loading-color-solid: 235, 235, 235;

  --color-muted: 0, 0, 0, 0.6;
  --color-turquise: 35, 160, 152;

  --document-width: 1200px;
  --document-margin: 1rem;

  --container-large-width: var(--document-width);
  --container-medium-width: 40rem;
  --container-small-width: 30rem;

  --focus-ring-width: 3px;
  --focus-ring-color: 236, 98, 160;

  --ease-in: cubic-bezier(0.4, 0, 1, 1);
  --ease-out: cubic-bezier(0, 0, 0.2, 1);
  --ease: cubic-bezier(0.4, 0, 0.2, 1);

  --color-goal-1: 229, 36, 59;
  --color-goal-2: 221, 166, 58;
  --color-goal-3: 76, 159, 56;
  --color-goal-4: 197, 25, 45;
  --color-goal-5: 255, 58, 33;
  --color-goal-6: 38, 189, 226;
  --color-goal-7: 252, 195, 11;
  --color-goal-8: 162, 25, 66;
  --color-goal-9: 253, 105, 37;
  --color-goal-10: 221, 19, 103;
  --color-goal-11: 253, 157, 36;
  --color-goal-12: 191, 139, 46;
  --color-goal-13: 63, 126, 68;
  --color-goal-14: 10, 151, 217;
  --color-goal-15: 86, 192, 43;
  --color-goal-16: 0, 104, 157;
  --color-goal-17: 25, 72, 106;
}

@media (min-width: 350px) {
  :root {
    --document-margin: 1.5rem;
  }
}

@media (min-width: 400px) {
  :root {
    --document-margin: 2rem;
  }
}

@media (min-width: 600px) {
  :root {
    --document-margin: 3rem;
  }
}

@media (min-width: 1000px) {
  :root {
    --document-margin: 5rem;
  }
}