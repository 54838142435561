@import "../base";
@import "../header";

/* @define Hero */
.Hero {
  display: flex;
  align-items: flex-end;
  min-height: 50vh;
  padding: 10vh 0;
  position: relative;
  z-index: 0;
  color: #fff;
  box-shadow: 0 var(--Header-height) 0 #fff;
}

.Hero::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.2) linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.Hero.is-loading {
  background: #000;
}

.Hero-image {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
  z-index: -2;
  user-select: none;
}

@supports (object-fit: cover) {
  .Hero-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
  }
}

.Hero-label {
  font-size: 0.75;
  font-weight: 600;
}

.Hero-symbol {
  display: inline-block;
  margin-right: 0.5em;
}

.Hero-title {
  max-width: 60rem;
  margin: 0 0 0.1em;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.1;
}

.Hero-body {
  max-width: 38rem;
  font-size: 1.25rem;
}

@media (min-width: 1000px) {
  .Hero-title {
    font-size: 4.5rem;
    line-height: 1.1;
  }

  .Hero-body {
    font-size: 1.5rem;
  }
}