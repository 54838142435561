@import "../base";

/* @define Callout */
.Callout {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  padding: 3.5rem 0 1.5rem;
  /* overflow: hidden; */
}

.Callout-blobs {
  overflow: hidden;
}

.Callout-art {
  width: 80%;
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 90rem;
  z-index: -1;
  pointer-events: none;
}

.Callout-button {
  border-radius: 2rem;
  text-align: center;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1.125rem;
  min-width: 9.5rem;
  margin: 2rem auto 0;
  display: inline-block;
  transition: background 125ms var(--ease-out), color 125ms var(--ease-out);
  user-select: none;
  background: #23A098;
  color: #fff;
}

.Callout-button:hover {
  color: #fff;
  background: #1a7973;
}

.Callout-button:active {
  background: #156964;
  transition: none;
}

@media (min-width: 370px) {
  .Callout-copy {
    display: block;
    margin-left: 0.8rem;
  }
}

@media (min-width: 1000px) {
  .Callout {
    padding: 7rem 0 5rem;
  }
}


.Callout-abstract {
  display: none !important;
}

@media (min-width: 1000px) {
  .Callout-abstract {
    display: block;
    position: absolute;
    top: 17%;
    left: -17rem;
    pointer-events: none;
    z-index: -1;
    width: auto;
    height: 100%;
    transform: rotate(-165deg);
  }
  
  .Callout-abstract--2 {
    left: auto;
    height: 90%;
    width: auto;
    top: 5%;
    transform: rotate(190deg);
    right: -13rem;
  }
}
