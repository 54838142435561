@import "../base";

/* @define More */
.More {
  position: relative;
}

.More-summary {
  display: block;
}

.More-summary::-webkit-details-marker {
  display: none;
}

.More-body {
  display: none;
}

.More-checkbox:checked ~ .More-summary {
  display: none;
}

.More-checkbox:checked ~ .More-body {
  display: block;
}

.More-preamble {
  position: relative;
}

.More-preamble::after {
  content: "";
  width: 100%;
  height: 3em;
  max-height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1));
}

.More-toggle {
  display: block;
  margin-top: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
}
