@import "../base";

/* @define View */
.View {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.View--floating { 
  background: #fafafa;
}

.View-main {
  flex: 1 0 auto;
}

.View-landing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15vh 0 5vh;
}

.View-info {
  max-width: 35rem;
}

.View-page {
  margin: 15vh 0;
}

.View-panel {
  padding: 10vh 0;
  background-color: rgb(255, 229, 242);
}

.View--floating .View-panel {
  background-color: #fafafa;
}

.View-panelAside {
  max-width: 20rem;
  margin-top: 2.5rem;
  width: 100%;
}

.View-panel--white,
.View--floating .View-panel--white {
  background-color: #fff;
}

.View-panel--spillo,
.View--floating .View-panel--spillo { 
  background: #FFE5F2;
}

.View-panel--tight {
  position: relative;
  padding: 5vh 0;
}

.View-panel:not(.View-panel--white) + .View-panel:not(.View-panel--white) {
  margin-top: -10vh;
}

.View-panel--white + .View-panel--white {
  margin-top: -10vh;
}

@media (min-width: 1000px) {
  .View-panel {
    padding: 15vh 0;
  }

  .View-panel--tight {
    position: relative;
    padding: 8vh 0;
  }

  .View-panel:not(.View-panel--white) + .View-panel:not(.View-panel--white) {
    margin-top: -15vh;
  }

  .View-panel--white + .View-panel--white {
    margin-top: -15vh;
  }
}

@media (min-width: 1150px) {
  .View-panel--tight {
    min-height: 20rem
  }

  .View-panelAside {
    position: absolute;
    top: -3rem;
    right: 0;
  }
}

.View-panel--divided {
  padding-top: 0;
  padding-top: 2rem;
}

.View-login {
  background: #fff;
  box-shadow: 0 0.125rem 2.75rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  padding: 4rem 2rem;
  margin: var(--document-margin) 0;
  transition: box-shadow 150ms var(--ease-out);
}

.View-login:focus-within {
  box-shadow: 0 12px 44px rgba(0, 0, 0, 0.2);
  transition: box-shadow 150ms var(--ease);
}

@media (min-width: 1000px) {
  .View-landing {
    flex-direction: row;
    align-items: center;
  }

  .View-login {
    min-width: 25rem;
    margin: 0 0 0 var(--document-margin);
  }
}

.View-intro {
  position: relative;
  margin: 4.3rem 0;
}

.View-header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.View-heading {
  padding-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: auto;
}

/**
 * Thread grid
 */

.View-threads {
  display: flex;
  flex-wrap: wrap;
  margin: 0.75rem -0.5rem -0.5rem;
}

.View-threadsWrap {
  position: relative;
  overflow: hidden;
  margin: -2rem;
  padding: 2rem;
}

.View-threadsWrap--inline { 
  overflow: visible;
  margin: 1rem 0 2rem;
}

.View-overlay {
  background: #fff;
  border-radius: 0.625rem;
  padding: 1.25rem;
  max-width: 25rem;
  margin: 2rem auto;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 44px 0 rgba(0,0,0,0.10);
}

@media (min-width: 600px) {
  .View-overlay {
    padding: 3rem;
    max-width: 45rem;
  }
}

.View-alpha {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 229, 242, 0), rgba(255, 229, 242, 1));
  z-index: 1;
}

.View-ageArt {
  display: none;
}

@media (min-width: 650px) {
  .View-ageArt {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 43%;
    margin-right: 1rem;
  }
}

.View-threadsContent.is-overlayed {
  position: absolute;
  top: 2rem;
  left: 2rem;
  filter: blur(4px);
  width: calc(100% - 4rem);
  pointer-events: none;
  opacity: 0.5;
}

.View-thread {
  padding: 0.5rem;
  flex: 0 0 100%;
}

@media (min-width: 500px) {
  .View-threads {
    margin: 0.75rem -0.75rem -0.75rem;
  }

  .View-thread {
    flex: 0 0 50%;
    padding: 0.75rem;
    display: flex;
  }
}

@media (min-width: 1000px) {
  .View-thread {
    flex: 0 0 25%;
  }

  .View-thread--large {
    flex: 0 0 50%;
  }

  .View-thread--large .Card-panel::before { 
    padding-top: 29.2%;
  }

  .View-threads--wide .View-thread {
    flex: 0 0 calc(100% / 3);
  }
}

.View-abstract {
  display: none !important;
}

@media (min-width: 1000px) {
  .View-abstract {
    display: block;
    position: absolute;
    top: 17vh;
    left: -30vh;
    pointer-events: none;
    z-index: -1;
    width: auto;
    height: 30vh;
  }

  .View-abstract--2 {
    left: auto;
    height: 110vh;
    width: auto;
    top: 0;
    transform: rotate(-30deg);
    right: -29vh;
  }
}

.View-art {
  position: fixed;
  bottom: 0;
  right: -3vw;
  transform: translate(0, 25.5%);
  width: auto;
  height: 180vh;
  z-index: -1;
  pointer-events: none;
  user-select: none;
  display: none;
}

@media (min-width: 1000px) {
  .View-art {
    display: block;
    position: fixed;
    bottom: 0;
    right: -3vw;
    transform: translate(0, 25.5%);
    width: auto;
    height: 180vh;
    z-index: -1;
    pointer-events: none;
    user-select: none;
  }
}

.View-close {
  border: 10rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.125rem;
  background: #FFFFFF;
  box-shadow: -2px 2px 10px 0 rgba(0,0,0,0.10);
  border-radius: 30px;
  position: fixed;
  top: 1rem;
  right: var(--Header-margin);
  padding: 1.1rem 3.7rem 1.1rem 1.5rem;
  user-select: none;
  margin-right: -0.3rem;
  z-index: 10;
  transition: box-shadow 125ms var(--ease-out);
}

.View-close:hover {
  transition: box-shadow 125ms var(--ease);
  box-shadow: -2px 2px 20px 0 rgba(0,0,0,0.12);
}

.View-close:active {
  transition: box-shadow 150ms var(--ease);
  box-shadow: -2px 2px 5px 0 rgba(0,0,0,0.08);
  transition: none;
}

.View-close svg {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.875rem;
  right: 0.875rem;
}

.View-goals {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -0.25rem;
  padding-top: 3rem;
}

.View-goalsItem {
  flex-basis: 50%;
  width: 50%;
}

.View-goalsItem--static {
  opacity: 0.2;
  pointer-events: none
}

.View-goalsGoal {
  padding: 0.25rem;
  transition: box-shadow 150ms var(--ease);
}

.View-goalsGoal:hover {
  background: #fff;
  box-shadow: 0 2px 44px 0 rgba(0,0,0,0.10);
  border-radius: 6px;
  transition: box-shadow 150ms var(--ease-out);
}

@media (min-width: 480px) {
  .View-goalsItem {
    flex-basis: calc(100% / 3);
    width: calc(100% / 3);
  }
}

@media (min-width: 800px) {
  .View-goals {
    margin: -0.5rem;
  }

  .View-goalsItem {
    flex-basis: calc(100% / 6);
    width: calc(100% / 6);
  }

  /* IE bug */
  .View-goalsItem:nth-child(6n) {
    flex-basis: calc((100% / 6) - 1px);
    width: calc((100% / 6) - 1px);
  }

  .View-goalsGoal {
    padding: 0.5rem;
  }
}

@media (min-width: 1100px) {
  .View-goals {
    margin: -1rem;
  }

  .View-goalsGoal {
    padding: 1rem;
  }
}

.View-goalsGoal {
  display: block;
}

.View-goals svg {
  display: block;
}

.View-hero {
  background: #000;
  color: #fff;
  padding: 8rem 0 2rem;
}

@media (min-width: 1000px) {
  .View-hero {
    padding: 10rem 0 5rem;
  }
}

.View-hero--1 { background: rgb(var(--color-goal-1)); }
.View-hero--2 { background: rgb(var(--color-goal-2)); }
.View-hero--3 { background: rgb(var(--color-goal-3)); }
.View-hero--4 { background: rgb(var(--color-goal-4)); }
.View-hero--5 { background: rgb(var(--color-goal-5)); }
.View-hero--6 { background: rgb(var(--color-goal-6)); }
.View-hero--7 { background: rgb(var(--color-goal-7)); }
.View-hero--8 { background: rgb(var(--color-goal-8)); }
.View-hero--9 { background: rgb(var(--color-goal-9)); }
.View-hero--10 { background: rgb(var(--color-goal-10)); }
.View-hero--11 { background: rgb(var(--color-goal-11)); }
.View-hero--12 { background: rgb(var(--color-goal-12)); }
.View-hero--13 { background: rgb(var(--color-goal-13)); }
.View-hero--14 { background: rgb(var(--color-goal-14)); }
.View-hero--15 { background: rgb(var(--color-goal-15)); }
.View-hero--16 { background: rgb(var(--color-goal-16)); }
.View-hero--17 { background: rgb(var(--color-goal-17)); }

.View-back {
  display: inline-flex;
  padding: 0 var(--View-margin);
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
  height: var(--Header-height);
  user-select: none;
}

.View-back:hover {
  text-decoration: underline;
}

.View-back:active {
  opacity: 0.5;
}

.View-back svg {
  width: 0.4375rem;
  height: auto;
  margin-right: 0.5rem;
}

/**
 * Targets
 */

.View-targets {
  margin: 3rem 0 0 0;
  flex-wrap: wrap;
}

.View-targetsItem {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  flex: 0 0 calc(50% - 1rem);
  margin-bottom: 1.5rem;
}

.View-targetsItem > div {
  width: 100%;
}

.View-targetsIcon {
  flex: 0 0 6rem;
  margin-right: 1rem; 
  text-align: center;
}

.View-targetsNumber {
  background: #1a1a1a;
  margin-bottom: 2px;
  color: #fff;
  font-weight: bold;
  padding: 0.2rem 0.4rem;
  font-size: 1rem;
}

.View-targets--1 .View-targetsNumber { background: rgb(var(--color-goal-1)); }
.View-targets--2 .View-targetsNumber { background: rgb(var(--color-goal-2)); }
.View-targets--3 .View-targetsNumber { background: rgb(var(--color-goal-3)); }
.View-targets--4 .View-targetsNumber { background: rgb(var(--color-goal-4)); }
.View-targets--5 .View-targetsNumber { background: rgb(var(--color-goal-5)); }
.View-targets--6 .View-targetsNumber { background: rgb(var(--color-goal-6)); }
.View-targets--7 .View-targetsNumber { background: rgb(var(--color-goal-7)); }
.View-targets--8 .View-targetsNumber { background: rgb(var(--color-goal-8)); }
.View-targets--9 .View-targetsNumber { background: rgb(var(--color-goal-9)); }
.View-targets--10 .View-targetsNumber { background: rgb(var(--color-goal-10)); }
.View-targets--11 .View-targetsNumber { background: rgb(var(--color-goal-11)); }
.View-targets--12 .View-targetsNumber { background: rgb(var(--color-goal-12)); }
.View-targets--13 .View-targetsNumber { background: rgb(var(--color-goal-13)); }
.View-targets--14 .View-targetsNumber { background: rgb(var(--color-goal-14)); }
.View-targets--15 .View-targetsNumber { background: rgb(var(--color-goal-15)); }
.View-targets--16 .View-targetsNumber { background: rgb(var(--color-goal-16)); }
.View-targets--17 .View-targetsNumber { background: rgb(var(--color-goal-17)); }


@media (min-width: 500px) and (max-width: 800px) {
  .View-targetsItem {
    margin-bottom: 3rem;
    flex: 0 0 calc(50% - 2rem);
  }

  .View-targetsIcon {
    flex: 0 0 8rem;
    margin-right: 1.5rem;
  }

  .View-targetsNumber {
    font-size: 1.25rem;
  }
}

@media (min-width: 1100px) {
  .View-targetsItem {
    margin-bottom: 3rem;
    flex: 0 0 calc(50% - 2rem);
  }

  .View-targetsIcon {
    flex: 0 0 8rem;
    margin-right: 1.5rem;
  }

  .View-targetsNumber {
    font-size: 1.25rem;
  }
}

@media (min-width: 800px) {
  .View-targets {
    display: flex;
    justify-content: space-between;
  }
}

/**
 * Tips
 */

.View-tips {
  margin: 0 0 2rem;
}

.View-tipsItem {
  margin: 3rem 0 0;
}

.View-tipsIcon {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto 1rem;
}

.View-tipsIcon svg {
  width: 100%;
  height: 100%;
}

@media (min-width: 800px) {
  .View-tips {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .View-tipsItem {
    flex: 0 0 calc(50% - 2rem)
  }
}