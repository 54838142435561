@import "../base";

/* @define Card */
.Card {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  width: 100%;
  box-shadow: 0 2px 44px rgba(0, 0, 0, 0.1);
  transition: box-shadow 150ms var(--ease-out);
}

.Card.is-disabled {
  opacity: 0.4;
  pointer-events: none;
  box-shadow: none !important;
}

.Card:hover {
  transition: box-shadow 150ms var(--ease);
  box-shadow: 0 12px 44px rgba(0, 0, 0, 0.2);
}

.Card--file,
.Card--plain { 
  border: 1px solid #DADADA;
  box-shadow: none;
  transition: background 125ms var(--ease-out);
}

.Card--plain:hover {
  box-shadow: none;
}

.Card--file:hover {
  box-shadow: none;
  background: #f1f1f1;
  transition: background 125ms var(--ease);
}

.Card--file.Card--goal1 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-1), 0.8); }
.Card--file.Card--goal2 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-2), 0.8); }
.Card--file.Card--goal3 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-3), 0.8); }
.Card--file.Card--goal4 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-4), 0.8); }
.Card--file.Card--goal5 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-5), 0.8); }
.Card--file.Card--goal6 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-6), 0.8); }
.Card--file.Card--goal7 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-7), 0.8); }
.Card--file.Card--goal8 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-8), 0.8); }
.Card--file.Card--goal9 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-9), 0.8); }
.Card--file.Card--goal10 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-10), 0.8); }
.Card--file.Card--goal11 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-11), 0.8); }
.Card--file.Card--goal12 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-12), 0.8); }
.Card--file.Card--goal13 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-13), 0.8); }
.Card--file.Card--goal14 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-14), 0.8); }
.Card--file.Card--goal15 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-15), 0.8); }
.Card--file.Card--goal16 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-16), 0.8); }
.Card--file.Card--goal17 .Card-panel { border-top: 0.5rem solid rgba(var(--color-goal-17), 0.8); }


.Card-panel {
  position: relative;
  background-color: rgba(var(--loading-color-solid));
}

.Card--goal1 .Card-panel { background: rgba(var(--color-goal-1), 0.8); }
.Card--goal2 .Card-panel { background: rgba(var(--color-goal-2), 0.8); }
.Card--goal3 .Card-panel { background: rgba(var(--color-goal-3), 0.8); }
.Card--goal4 .Card-panel { background: rgba(var(--color-goal-4), 0.8); }
.Card--goal5 .Card-panel { background: rgba(var(--color-goal-5), 0.8); }
.Card--goal6 .Card-panel { background: rgba(var(--color-goal-6), 0.8); }
.Card--goal7 .Card-panel { background: rgba(var(--color-goal-7), 0.8); }
.Card--goal8 .Card-panel { background: rgba(var(--color-goal-8), 0.8); }
.Card--goal9 .Card-panel { background: rgba(var(--color-goal-9), 0.8); }
.Card--goal10 .Card-panel { background: rgba(var(--color-goal-10), 0.8); }
.Card--goal11 .Card-panel { background: rgba(var(--color-goal-11), 0.8); }
.Card--goal12 .Card-panel { background: rgba(var(--color-goal-12), 0.8); }
.Card--goal13 .Card-panel { background: rgba(var(--color-goal-13), 0.8); }
.Card--goal14 .Card-panel { background: rgba(var(--color-goal-14), 0.8); }
.Card--goal15 .Card-panel { background: rgba(var(--color-goal-15), 0.8); }
.Card--goal16 .Card-panel { background: rgba(var(--color-goal-16), 0.8); }
.Card--goal17 .Card-panel { background: rgba(var(--color-goal-17), 0.8); }

.Card--file .Card-panel {
  background: none;
}

.Card-panel--image::before {
  content: "";
  display: block;
  padding-top: 61%;
}

.Card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  position: relative;
}

.Card-goal {
  position: absolute;
  top: -1.4rem;
  right: 1rem;
  width: 2.4rem;
  height: 2.4rem;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
  user-select: none;
}

.Card-goal + .Card-goal {
  right: 3.8rem
}

.Card-goal:nth-child(3) {
  right: 6.6rem
}

.Card-goal--1 { background: rgb(var(--color-goal-1)); }
.Card-goal--2 { background: rgb(var(--color-goal-2)); }
.Card-goal--3 { background: rgb(var(--color-goal-3)); }
.Card-goal--4 { background: rgb(var(--color-goal-4)); }
.Card-goal--5 { background: rgb(var(--color-goal-5)); }
.Card-goal--6 { background: rgb(var(--color-goal-6)); }
.Card-goal--7 { background: rgb(var(--color-goal-7)); }
.Card-goal--8 { background: rgb(var(--color-goal-8)); }
.Card-goal--9 { background: rgb(var(--color-goal-9)); }
.Card-goal--10 { background: rgb(var(--color-goal-10)); }
.Card-goal--11 { background: rgb(var(--color-goal-11)); }
.Card-goal--12 { background: rgb(var(--color-goal-12)); }
.Card-goal--13 { background: rgb(var(--color-goal-13)); }
.Card-goal--14 { background: rgb(var(--color-goal-14)); }
.Card-goal--15 { background: rgb(var(--color-goal-15)); }
.Card-goal--16 { background: rgb(var(--color-goal-16)); }
.Card-goal--17 { background: rgb(var(--color-goal-17)); }


.Card-panel--image img {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  width: auto;
  transform: translate(-50%, -50%);
  user-select: none;
}

@supports (object-fit: cover) {
  .Card-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
  }
}

.Card-title {
  margin: 0 0 0.5em;
  font-size: 1.25rem;
  font-weight: 600;
}

.Card-prefix {
  background: rgb(var(--document-color));
  color: #fff;
  padding: 0.15em 0.3em;
  margin-right: 0.7rem;
  border-radius: 0.2em;
  font-size: 0.9em;
  white-space: nowrap;
  display: inline-block;
}

.Card-body {
  font-size: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card-link {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  color: transparent;
  width: 100%;
  height: 100%;
}

.Card-link:focus-visible {
  border: var(--focus-ring-width) solid rgb(var(--focus-ring-color));
}

.Card-warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(10deg);
  color: #fff;
  font-weight: bold;
  font-size: 1.25rem;
  transform-origin: center;
  border: 3px solid;
  border-radius: 7px;
  padding: 0.2rem 0.8rem;
  white-space: nowrap;
  box-shadow: 1px 1px 1px black, inset 1px 1px 1px black;
  text-shadow: 1px 1px 1px black;
}